<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-select
            :items="salutationItems"
            :error-messages="salutationErrors"
            :label="$t('salutation')"
            @blur="$v.item.profile.salutation.$touch()"
            @input="$v.item.profile.salutation.$touch()"
            v-model="item.profile.salutation"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="titleErrors"
            :label="$t('title')"
            @blur="$v.item.profile.title.$touch()"
            @input="$v.item.profile.title.$touch()"
            v-model="item.profile.title"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="firstNameErrors"
            :label="$t('firstName')"
            @blur="$v.item.profile.firstName.$touch()"
            @input="$v.item.profile.firstName.$touch()"
            required
            v-model="item.profile.firstName"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="lastNameErrors"
            :label="$t('lastName')"
            @blur="$v.item.profile.lastName.$touch()"
            @input="$v.item.profile.lastName.$touch()"
            v-model="item.profile.lastName"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="companyErrors"
            :label="$t('company')"
            @blur="$v.item.profile.company.$touch()"
            @input="$v.item.profile.company.$touch()"
            v-model="item.profile.company"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="emailErrors"
            :label="$t('email')"
            @blur="$v.item.email.$touch()"
            @input="$v.item.email.$touch()"
            required
            v-model="item.email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="6">
          <v-file-input
            :rules="imageRules"
            :label="$t('profileImage')"
            :placeholder="$t('profileImagePlaceholder')"
            v-model="uploadImage"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            @change="onFileUpload"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-checkbox
            :error-messages="enabledErrors"
            :label="$t('enabled')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.enabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DocumentMixin from "@/mixins/DocumentMixin";

export default {
  name: "SpeakerForm",
  mixins: [validationMixin, DocumentMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },

    errors: {
      type: Object,
      default: () => {},
    },

    initialValues: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPassword: false,
      email: null,
      plainPassword: null,
      profile: {
        salutation: null,
        title: null,
        company: null,
        firstName: null,
        lastName: null,
      },
      enabled: false,
      locked: true,
      salutationItems: ["Frau", "Herr", "Divers"],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      uploadImage: null,
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    salutationErrors() {
      const errors = [];

      if (!this.$v.item.profile.salutation.$dirty) {
        return errors;
      }
      has(this.violations, "salutation") &&
        errors.push(this.violations.salutation);

      return errors;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.item.profile.title.$dirty) {
        return errors;
      }
      has(this.violations, "title") && errors.push(this.violations.title);

      return errors;
    },
    companyErrors() {
      const errors = [];

      if (!this.$v.item.profile.company.$dirty) {
        return errors;
      }
      has(this.violations, "company") && errors.push(this.violations.company);

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.item.email.$dirty) {
        return errors;
      }

      has(this.violations, "email") && errors.push(this.violations.email);

      !this.$v.item.email.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.item.profile.firstName.$dirty) {
        return errors;
      }

      has(this.violations, "profile.firstName") &&
        errors.push(this.violations.profile.firstName);

      !this.$v.item.profile.firstName.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.item.profile.lastName.$dirty) {
        return errors;
      }

      has(this.violations, "profile.lastName") &&
        errors.push(this.violations.profile.lastName);

      !this.$v.item.profile.lastName.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);

      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    onFileUpload(file) {
      this.uploadDocument(file);
    },
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.profile.image = this.uploadedDocument["@id"];
    },
  },
  validations: {
    item: {
      profile: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        salutation: {},
        title: {},
        company: {},
      },
      email: {
        required,
      },
      enabled: {},
    },
  },
};
</script>
